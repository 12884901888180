import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NetlifyForm from 'react-netlify-form';
import Layout from '../components/layout';
// import Hero from '../components/hero';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import SEO from '../components/seo';
import Field from '../components/field';
import Img from 'gatsby-image';

function YardSign() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "yardsigns.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Get a Vanna Howard Yard Sign!"
        description="Sign up now and we'll get your yard signs delivered as soon as possible!"
        image={`https://vannahoward.com/${data.placeholderImage.childImageSharp.fluid.src}`}
      />

      {/* <Hero size="" title="Get Involved" color="primary" centered /> */}
      <div className="bg-split">
        <div className="container section">
          <div className="columns is-variable is-8">
            <div className="column content">
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
              <br />
              <p>Sign up now and we'll get your yard signs delivered as soon as possible!</p>
            </div>
            <div className="column is-one-half">

              <NetlifyForm
                name="Yard Sign"
                recaptcha={{
                  sitekey: '6Lc9qO4UAAAAAMqtugSDYdFnZcB6JPBTMaFjIr76',
                  size: 'invisible',
                }}
              >
                {({ recaptcha, loading, error, success }) => (
                  <div>
                    {error && <div className="notification is-danger">Please try again.</div>}
                    {success && (
                      <div className="content">
                        <p>
                          <strong>Thank you for signing up!</strong>
                        </p>
                        <p>
                          While we have your attention, can we ask one more thing? Yard signs (and
                          campaigns in general) are expensive - can you chip in a few dollars to
                          help us keep pace with demand?
                        </p>
                        <p className="has-text-centered">
                          <a
                            href="https://secure.actblue.com/contribute/page/vannahoward?amount=25&refcode=yardsigns"
                            className="button is-danger"
                          >
                            <span className="icon" style={{marginRight: 10}}>
                              <FontAwesomeIcon size="" icon={faCheck} />
                            </span>
                            <strong className="is-uppercase">Donate Now</strong>
                          </a>
                        </p>
                      </div>
                    )}
                    {!loading && !success && (
                      <>
                      <p className="title is-6 heading">I want a Yard Sign!</p>
                        <Field
                          type="email"
                          name="email"
                          className="input"
                          id="email"
                          placeholder="yourname@something.com"
                          required
                          label="Email Address"
                        />

                        <div className="field is-horizontal">
                          <div className="field-body">
                            <Field
                              type="text"
                              name="firstName"
                              className="input is-grouped"
                              id="firstName"
                              required
                              label="First Name"
                            />
                            <Field
                              type="text"
                              name="lastName"
                              className="input is-grouped"
                              id="lastName"
                              required
                              label="Last Name"
                            />
                          </div>
                        </div>

                        <Field
                          type="text"
                          name="addressStreet"
                          id="addressStreet"
                          label="Street Address"
                        />

                        <Field
                          type="select"
                          name="addressCity"
                          id="addressCity"
                          label="City/Town"
                          options={[
                            { label: 'Lowell', value: 'lowell' },
                            { label: 'Chelmsford', value: 'chelmsford' },
                          ]}
                        />

                        <Field type="textarea" name="comments" label="Comments?" rows="2" />

                        <div className="field">
                          <div className="control">
                            <button className="button is-primary" type="submit">
                              Sign Me Up!
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {recaptcha}
                  </div>
                )}
              </NetlifyForm>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default YardSign;
